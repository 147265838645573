import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import NodeBuzz from "./NodeBuzz";
import NodeLight from "./NodeLight";
import NodeFlashFirmware from "./NodeFlashFirmware";
import { Tag } from "../../layout/Tag";
import { generateColor } from "../../../helpers/utils";
import { TimeAgo } from "../../../helpers";
import UpgradeFirmwareBtn from "../cluster/UpgradeFirmware";

type SystemNodesProps = {
    system: CoreSystem;
    refetch: () => void;
};

export default function SystemNodes({ system, refetch }: SystemNodesProps) {
    const { t } = useTranslation();
    const [selectedNode, selectNode] = useState<null | CoreNode>(null);

    return (
        <div>
            <h5 className="mb-2 text-xl font-medium ">{t("Nodes")}</h5>

            <hr className="border-t border-muted mb-4" />

            <div className="flex justify-between gap-4">
                {selectedNode && (
                    <div className="w-1/4 bg-gray-300 order-2 rounded">
                        <div className="flex flex-col p-4 text-sm">
                            <div className="font-bold">
                                Node {selectedNode?.address}
                                {selectedNode?.clientRef && (
                                    <> ({selectedNode.clientRef})</>
                                )}
                                :
                            </div>
                            <div className="flex gap-1">
                                <Tag
                                    bgColor={generateColor(selectedNode.status)}
                                >
                                    {selectedNode.status}
                                </Tag>
                                <Tag
                                    bgColor={generateColor(
                                        selectedNode.reachable ? "Yes" : "No"
                                    )}
                                >
                                    {selectedNode.reachable
                                        ? "Reachable"
                                        : "Not Reachable"}
                                </Tag>
                            </div>
                            <div>Serial: {selectedNode?.serial || "-"}</div>
                            <div>
                                Firmware: {selectedNode?.firmwareVersion || "-"}
                            </div>
                            <div>
                                Hardware: {selectedNode?.hardwareVersion || "-"}
                            </div>

                            {selectedNode?.peripherals && (
                                <div className="flex flex-col py-4 text-sm">
                                    <div className="font-bold">
                                        Peripherals:
                                    </div>
                                    {selectedNode.peripherals.map(
                                        (peripheral) => (
                                            <div
                                                className="mb-2"
                                                key={`p-${selectedNode.address}-${peripheral.address}`}
                                            >
                                                <div>
                                                    Address:{" "}
                                                    {peripheral.address}
                                                </div>
                                                <div className="flex gap-1">
                                                    <Tag
                                                        bgColor={generateColor(
                                                            peripheral.status
                                                        )}
                                                    >
                                                        {peripheral.status}
                                                    </Tag>

                                                    <Tag
                                                        bgColor={generateColor(
                                                            peripheral.reachable
                                                                ? "Yes"
                                                                : "No"
                                                        )}
                                                    >
                                                        {peripheral.reachable
                                                            ? "Reachable"
                                                            : "Not Reachable"}
                                                    </Tag>
                                                </div>
                                                <div>
                                                    Model:{" "}
                                                    <Tag
                                                        bgColor={generateColor(
                                                            peripheral.model
                                                        )}
                                                    >
                                                        {peripheral.model}
                                                    </Tag>
                                                    <div>
                                                        Last Update:{" "}
                                                        <TimeAgo
                                                            date={
                                                                peripheral.updatedAt
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="flex flex-col gap-4 order-1">
                    {system.nodes &&
                        system.nodes.map((branchNode) => (
                            <div
                                className="p-0"
                                key={`branch-node-${branchNode.address}`}
                            >
                                <div className="w-full flex items-center gap-2">
                                    <div
                                        className="border rounded p-2 bg-gray-100 min-w-[100px] flex items-center cursor-pointer hover:border-gray-400"
                                        onClick={() => {
                                            if (
                                                selectedNode &&
                                                selectedNode.address ===
                                                    branchNode.address
                                            ) {
                                                selectNode(null);
                                            } else {
                                                selectNode(branchNode);
                                            }
                                        }}
                                    >
                                        <div className="w-full flex items-center gap-2 ">
                                            <div
                                                className={classNames(
                                                    "flex w-12 h-8  text-white items-center justify-center rounded text-xs",
                                                    branchNode.status ===
                                                        "Online"
                                                        ? "bg-green-600"
                                                        : "bg-red-600"
                                                )}
                                            >
                                                {branchNode.status}
                                            </div>
                                            <div className="text-sm text-center font-bold">
                                                {branchNode.clientRef ||
                                                    branchNode.address}
                                            </div>
                                        </div>
                                    </div>

                                    {branchNode.nodes &&
                                        branchNode.nodes.map((leafNode) => (
                                            <div
                                                key={`leaf-node-${leafNode.address}`}
                                                className={classNames(
                                                    "border rounded p-2 bg-gray-100 w-[100px] flex-row items-center cursor-pointer hover:border-gray-400",
                                                    selectedNode &&
                                                        selectedNode.address ===
                                                            leafNode.address &&
                                                        "border-gray-400 bg-gray-300"
                                                )}
                                                onClick={() => {
                                                    if (
                                                        selectedNode &&
                                                        selectedNode.address ===
                                                            leafNode.address
                                                    ) {
                                                        selectNode(null);
                                                    } else {
                                                        selectNode(leafNode);
                                                    }
                                                }}
                                            >
                                                <div className="w-full flex items-center gap-2">
                                                    <div
                                                        className={classNames(
                                                            "flex w-12 h-8 text-white items-center justify-center rounded text-xs",
                                                            leafNode.status ===
                                                                "Online"
                                                                ? "bg-green-600"
                                                                : "bg-red-600"
                                                        )}
                                                    >
                                                        {leafNode.status}
                                                    </div>
                                                    <div className="text-sm text-center font-bold">
                                                        <div>
                                                            {leafNode.clientRef ||
                                                                leafNode.address}
                                                        </div>
                                                        <div className="flex gap-1">
                                                            {leafNode.peripherals &&
                                                                leafNode.peripherals.map(
                                                                    (
                                                                        peripheral
                                                                    ) => (
                                                                        <div
                                                                            key={`peripheral-${peripheral.address}`}
                                                                            className={classNames(
                                                                                "bg-green-600 h-2 w-1 rounded",
                                                                                peripheral.status ===
                                                                                    "Online"
                                                                                    ? "bg-green-600"
                                                                                    : "bg-red-600"
                                                                            )}
                                                                        ></div>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <hr className="border-t border-muted my-4" />

            <div className="flex justify-start gap-2">
                <NodeBuzz selectedNode={selectedNode} system={system} />
                <NodeLight selectedNode={selectedNode} system={system} />
                <NodeFlashFirmware system={system} refetch={refetch} />
                <UpgradeFirmwareBtn system={system} buttonLabel="Upgrade all nodes"/>
            </div>
        </div>
    );
}
