import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_FIRMWARE_UPGRADE, MUTATION_CANCEL_FIRMWARE_UPGRADE } from "../../../graphql/mutations";
import Modal, { ModalContext } from "../../layout/Modal";
import classNames from "classnames";
import { useLoading } from "../../context/LoadingContext";

type UpgradeFirmwareProps = {
    system: CoreSystem;
    refetch?: () => void  | null;
    onClick?: (() => void) | null;
    buttonLabel?: string;
};

const UpgradeFirmware = ({ system, refetch, onClick = null, buttonLabel}: UpgradeFirmwareProps) => {
    const { t } = useTranslation();
    const { setLoading } = useLoading();

    const [executeUpgrade] = useMutation(MUTATION_FIRMWARE_UPGRADE);
    const [executeUpgradeCancel] = useMutation(MUTATION_CANCEL_FIRMWARE_UPGRADE);

    const { openModal, closeModal, isModalOpen } = useContext(ModalContext);

    const [running, setRunning] = useState(false);
    const MODAL_ID = "upgrade-system-firmware-modal";

    const getProcessManager = () => {
        const processManager = system.processManagers.find(
            (manager) => manager.type === "ALL_FIRMWARE_UPGRADE"
        );
        if (processManager) {
            return processManager;
        } else {
            return {
                status: "UP_TO_DATE",
                lastMessage: "UP_TO_DATE",
                progress: 0,
            }
        }
    }

    const getProgress = () => {
        const processManager = getProcessManager();
        if (processManager) {
            return Math.round(processManager.progress * 100);
        }
        return 0;
    }

    const handleUpgrade = () => {
        setRunning(true);
        executeUpgrade({
            variables: {
                macAddress: system.macAddress,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setTimeout(() => {
                    setRunning(false);
                }, 1000);
                if (refetch) {
                    refetch();
                }
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const cancelUpgrade = () => {
        setRunning(true);
        executeUpgradeCancel({
            variables: {
                macAddress: system.macAddress,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setRunning(false);
                if (refetch) {
                    refetch();
                }
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
        openModal(MODAL_ID);
    }

    return (
        <>

            {system.firmwareVersionStatus == "OUT_OF_DATE" ? (
                <button
                    id="btn-system-open-upgrade-modal"
                    className="button info w-48"
                    onClick={() => handleOnClick()}
                >
                    {t(buttonLabel)}
                </button>
            ) : system.firmwareVersionStatus == "UP_TO_DATE" ? (
                <button
                    id="btn-system-open-upgrade-modal"
                    className="button success w-48"
                    onClick={() => handleOnClick()}
                >
                    {t("Firmware updated")}
                </button>
            ) : (
                <button
                    id="btn-system-open-upgrade-modal"
                    className="button warning w-48"
                    onClick={() => handleOnClick()}
                >
                    {t("Upgrading")}
                </button>
            )}
            <Modal
                title="Upgrade all firmware nodes"
                isOpen={isModalOpen(MODAL_ID)}
                onClose={() => closeModal(MODAL_ID)}
                size="max-w-xl"
                children={
                    <>
                        {system.firmwareVersionStatus == "OUT_OF_DATE" ? (
                            <div className="mt-4 text-sm">
                                <p>
                                    {t(
                                        "FIRMWARE_UPGRADE_WARNING_MESSAGE"
                                    )}
                                </p>
                            </div>
                        ) : system.firmwareVersionStatus == "UP_TO_DATE" ? (
                            <div className="mt-4 text-sm">
                                <p>
                                    {t(
                                        "FIRMWARE_UPGRADED_MESSAGE"
                                    )}
                                </p>
                            </div>
                            
                        ) : (

                            <div className="mt-4 text-sm">
                                <p>
                                    {t(
                                        "FIRMWARE_UPGRADE_IN_PROGRESS_MESSAGE"
                                    )}
                                </p>
                                <p>
                                    <div className="inline-flex items-center rounded-full text-white" style={{ backgroundColor: 'rgb(245, 158, 11)' }}><span className="text-xs font-medium px-1 py-1 px-2">{t(getProcessManager().status)}</span></div>
                                </p>
                                <p>
                                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                                        <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${getProgress()}%` }}> {getProgress()}%</div>
                                    </div>
                                </p>
                                <p>
                                    {t("LATEST_STATE")}: {t(getProcessManager().lastMessage)}
                                </p>
                            </div>
                        )}
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light  w-32"
                                type="button"
                                onClick={() => closeModal(MODAL_ID)}
                            >
                                {t("Close")}
                            </button>
                            {["OUT_OF_DATE", "UP_TO_DATE"].includes(system.firmwareVersionStatus) ? (
                            
                                <button
                                    id="btn-system-upgrade"
                                    className={classNames(
                                        "button info w-32",
                                        running ? "light" : ""
                                    )}
                                    type="button"
                                    onClick={handleUpgrade}
                                    disabled={running}
                                >
                                    {running ? t("Loading...") : t("Start Upgrade")}
                                </button>
                            ) : (

                                <button
                                    id="btn-system-upgrade"
                                    className={classNames(
                                        "button warning w-32",
                                        running ? "light" : ""
                                    )}
                                    type="button"
                                    onClick={cancelUpgrade}
                                    disabled={running}
                                >
                                    {running ? t("Loading...") : t("Cancel Upgrade")}
                                </button>

                            )}
                        </div>
                    </>
                }
            />
        </>
    );
};

const UpgradeFirmwareBtn = ({ system, refetch, onClick = null, buttonLabel = "Upgrade"}: UpgradeFirmwareProps) => {
    const { t } = useTranslation();

    if (!system) {
        return null;
    }

    // if (system.maintenanceMode === false || system.reachable === false) {
    if (false) {
        return (
            <button
                className={classNames("button info w-48 disabled")}
                type="button"
            >
                {t("Upgrade")}
            </button>
        );
    }

    return <UpgradeFirmware system={system} refetch={refetch} onClick={onClick} buttonLabel={buttonLabel}/>;
};

export default UpgradeFirmwareBtn;
