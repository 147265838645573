import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { MUTATION_DEMO_CALIBRATION } from "../../../../graphql/mutations";
import { MUTATION_MARK_AISLE_COMMISSIONED } from "../../../../graphql/mutations";
import Modal, { ModalContext } from "../../../layout/Modal";
import { set } from "date-fns";

type VerifySensorsProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
    step: string;
    setStep: (step: VerifyStep) => void;
};


type VerifyStep = "Verify" | "Verify Again" | "Starting..." | "Running..."| "Confirmed";

const VerifySensors = ({ system, selectedNode, step, setStep }: VerifySensorsProps) => {
    const { t } = useTranslation();
    const [executeVerifySensors] = useMutation(MUTATION_DEMO_CALIBRATION);

    

    const handleVerifySensors = () => {
        setStep("Starting...");
        executeVerifySensors({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setStep("Running...");
                setTimeout(() => {
                    setStep("Verify Again");
                }, 3000);
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    };

    const buttonRef = useRef(null);
    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    });

    if (step === "Verify" || step === "Verify Again" || step === "Confirmed") {
        return (
            <button
                id="btn-aisle-start-verify"
                className={classNames(
                    "button info w-32",
                    step == "Confirmed" ? "disabled" : ""
                )}

                type="button"
                onClick={handleVerifySensors}
                ref={buttonRef}
            >
                {t(step == "Verify" ? "Verify" : "Verify Again")}
            </button>
        );
    }

    return (
        <button
            id="btn-aisle-start-verify"
            className={"button info w-32 light disabled"}
            type="button"
            disabled={true}
        >
            {t(step)}
        </button>
    );
};

type VerifySensorsModalProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
    label?: string;
    onClick?: (() => void) | null;
    showButton?: boolean;
};

const VerifySensorsModal = ({
    system,
    selectedNode,
    label,
    onClick = null,
    showButton = true,
}: VerifySensorsModalProps) => {
    const { t } = useTranslation();
    const { openModal, closeModal, isModalOpen } = useContext(ModalContext);

    const MODAL_ID = "verifiy-sensors-order-modal";

    const [step, setStep] = useState<VerifyStep>("Verify");
    const [markAisleCommissioned] = useMutation(MUTATION_MARK_AISLE_COMMISSIONED);

    const handleClick = () => {
        openModal(MODAL_ID);
        if (onClick) {
            onClick();
        }
    };

    const confirmCommissioning = () => {
        markAisleCommissioned({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                setStep("Confirmed");
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    }

    const handleCloseModel = () => {
        closeModal(MODAL_ID);
        setStep("Verify");
    };

    return (
        <>
            {showButton && (
                <button 
                    id="btn-aisle-open-verify"
                    className="button indigo min-w-32"
                    onClick={handleClick}
                >
                    {t(label)}
                </button>
            )}

            <Modal
                title={t("Verify Sensor Order")}
                isOpen={isModalOpen(MODAL_ID)}
                onClose={handleCloseModel}
                size="max-w-2xl"
                children={
                    <>
                        <div className="mt-4 text-sm">
                            {step === "Confirmed" ? (
                                <p>
                                    {t("AISLE_MARKED_COMMISSIONED")}
                                </p>
                            ) : (
                                <>
                                    <p>
                                        {t(
                                            "Use this tool to verify that the order of the sensors is correct."
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "After clicking Verify, the red sensor lights on the floor will switch off. They will then turn on in sequence, starting from A column."
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "Once all on, they will then turn off in the same sequence."
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "Once all off, they will all turn back on after a few seconds."
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "If the sequence is not correct, re-run the Commissioning Tool for this aisle."
                                        )}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light w-32"
                                type="button"
                                onClick={handleCloseModel}
                            >
                                {t("Close")}
                            </button>
                            { (step !== "Confirmed") && (
                                <>
                                    <VerifySensors
                                        system={system}
                                        selectedNode={selectedNode}
                                        step={step}
                                        setStep={setStep}
                                    />
                                    <button
                                        className={classNames(
                                            "button success w-32",
                                            step == "Verify Again" ? "" : "disabled"
                                        )}
                                        type="button"
                                        onClick={() => confirmCommissioning()}
                                    >
                                        {t("Confirm")}
                                    </button>
                                </>
                            ) }
                            
                        </div>
                    </>
                }
            />
        </>
    );
};

const VerifySensorsBtn = ({
    system,
    selectedNode,
    label = "Verify Sensor Order",
    onClick = null,
    showButton = true,
}: VerifySensorsModalProps) => {
    if (!system) {
        return null;
    }

    if (!system.maintenanceMode) {
        return null;
    }

    return (
        <VerifySensorsModal
            system={system}
            selectedNode={selectedNode}
            label={label}
            onClick={onClick}
            showButton={showButton}
        />
    );
};

export default VerifySensorsBtn;
