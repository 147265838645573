import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box } from "../../layout/Boxes";
import { Tag } from "../../layout/Tag";
import { formatDateTime, generateColor } from "../../../helpers/utils";
import UpgradeSystemBtn from "./UpgradeSystem";
import UpgradeFirmwareBtn from "./UpgradeFirmware";
import { useUser } from "../../context/UserContext";

type ClusterInfoProps = {
    system: CoreSystem;
    site: CoreSite;
    refetch: () => void;
};

const ClusterInfo = ({ system, site, refetch }: ClusterInfoProps) => {
    const { t } = useTranslation();
    const { hasScope } = useUser();
    return (
        <>
            <Box title="Info">
                <table className="table-auto w-full my-3">
                    <tbody>
                        <tr>
                            <td>{t("Name")}</td>
                            <td>{system.clientRef}</td>
                        </tr>
                        <tr>
                            <td>{t("Hostname")}</td>
                            <td>{system.hostname}</td>
                        </tr>
                        <tr>
                            <td>{t("Mac Address")}</td>
                            <td>{system.macAddress}</td>
                        </tr>
                        <tr>
                            <td>{t("Site")}</td>
                            <td>
                                <Link to={`/sites/${site.reference}`}>
                                    {site.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("Status")}</td>
                            <td>
                                <Tag bgColor={generateColor(system.uiStatus)}>
                                    {system.uiStatus}
                                </Tag>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("Commissioned")}</td>
                            <td>
                                {system.commissioned ? (
                                    <Tag bgColor={generateColor("Yes")}>
                                        {t("Yes")}
                                    </Tag>
                                ) : (
                                    <Tag bgColor={generateColor("No")}>
                                        {t("No")}
                                    </Tag>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>{t("Software Status")}</td>
                            <td>
                                {["UP_TO_DATE", "UPGRADING"].includes(
                                    system.softwareStatus
                                ) ? (
                                    <Tag
                                        className="px-4"
                                        bgColor={generateColor(
                                            system.softwareStatus
                                        )}
                                    >
                                        {t(system.softwareStatus)}
                                    </Tag>
                                ) : (
                                    <UpgradeSystemBtn
                                        system={system}
                                        refetch={refetch}
                                    />
                                )}
                            </td>
                        </tr>
                        {hasScope("superadmin") && (
                            <tr>
                                <td>{t("Firmware version Status")}</td>
                                <td>
                                    
                                    {["UP_TO_DATE"].includes(
                                        system.firmwareVersionStatus
                                    ) ? (
                                        <Tag
                                            className="px-4"
                                            bgColor={generateColor(
                                                system.firmwareVersionStatus
                                            )}
                                        >
                                            {t(system.firmwareVersionStatus)}
                                        </Tag>
                                    ) : (
                                        <UpgradeFirmwareBtn
                                            system={system}
                                            refetch={refetch}
                                        />
                                    )}
                                </td>
                            </tr> 
                        )}
                        <tr>
                            <td>{t("Last update")}</td>
                            <td>{formatDateTime(system.heartbeatCommsAt)}</td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <Box title="Configuration">
                <table className="table-auto w-full my-3">
                    <tbody>
                        <tr>
                            <td>{t("Aisles per System")}</td>
                            <td>{system.configNoAisles}</td>
                        </tr>
                        <tr>
                            <td>{t("Bag (4T) Columns per Aisle")}</td>
                            <td>{site.configNoColumns}</td>
                        </tr>
                        <tr>
                            <td>{t("OV per Aisle")}</td>
                            <td>{site.configNoColumnsOv}</td>
                        </tr>
                        <tr>
                            <td>{t("Sensors per Bag (4T) Column")}</td>
                            <td>{site.configNoSensors}</td>
                        </tr>
                        <tr>
                            <td>{t("Sensors per OV")}</td>
                            <td>{site.configNoSensorsOv}</td>
                        </tr>
                    </tbody>
                </table>
            </Box>
        </>
    );
};

export default ClusterInfo;
