import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { getCookie } from "../helpers/cookies";
import config from "../config";
import { uiSystemStatus } from "../helpers/utils";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

const accountReference: string | null = getCookie("account_reference");
const accessKey: string | null = getCookie("access_key");
let auth = {};
let credentials = "include";

if (accountReference && accessKey) {
    console.log("*** Authenticating Core with cookies ***");
    auth = { account_reference: accountReference, access_key: accessKey };
    credentials = "omit";
}

const httpLink = new HttpLink({
    uri: config.apiUrl + "/graphql/v1",
    credentials,
    headers: {
        "Content-Type": "application/json",
        ...auth,
    },
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: config.wsUrl + "/graphql/v1",
        connectionParams: {
            ...auth,
        },
        lazy: true,
        on: {
            connected: () => console.log("WebSocket Connected"),
            error: (error) => console.error("WebSocket Error", error),
        },
    })
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        typePolicies: {
            SystemType: {
                keyFields: ["macAddress"],
                fields: {
                    uiName: {
                        read(_, { readField }) {
                            return systemUiName({ readField });
                        },
                    },
                    uiStatus: {
                        read(_, { readField }) {
                            return systemUiStatus({ readField });
                        },
                    },
                },
            },
            SystemBaseType: {
                keyFields: ["macAddress"],
                fields: {
                    uiName: {
                        read(_, { readField }) {
                            return systemUiName({ readField });
                        },
                    },
                    uiStatus: {
                        read(_, { readField }) {
                            return systemUiStatus({ readField });
                        },
                    },
                },
            },
            SystemNode: {  // Deprecated (older Core has this type)
                keyFields: ["address"],
                fields: {
                    uiName: {
                        read(_, { readField }) {
                            return nodeUiName({ readField });
                        },
                    },
                },
            },
            Node: {
                keyFields: ["address"],
                fields: {
                    uiName: {
                        read(_, { readField }) {
                            return nodeUiName({ readField });
                        },
                    },
                },
            },
            // SystemIssueConnection: {
            //     fields: {
            //         edges: {
            //             merge(existing = [], incoming) {
            //                 console.log("** existing", existing);
            //                 console.log("** incoming", incoming);
            //                 return [...incoming];
            //             },
            //         },
            //     },
            // },
            // SystemPeripheral: {
            //     keyFields: ["aliasAddress", "aliasSensorIndex"],
            // },
            Site: {
                keyFields: ["reference"],
            },
            AccountType: {
                keyFields: ["reference"],
            },
            SystemIssue: {
                keyFields: ["number"],
            },
            CustomerOrganisationQueries: {
                keyFields: ["account"],
            },
        },
    }),
});

function systemUiStatus({ readField }: { readField: ReadFieldFunction }) {
    const status = readField<string>("status");
    const reachable = readField<boolean>("reachable");
    const highTraffic = readField<boolean>("highTraffic");
    const maintenanceMode = readField<boolean>("maintenanceMode");

    return uiSystemStatus(status, reachable, highTraffic, maintenanceMode);
}

function systemUiName({ readField }: { readField: ReadFieldFunction }) {
    const clientRef = readField<string>("clientRef");
    const macAddress = readField<boolean>("macAddress");

    return clientRef || macAddress;
}

function nodeUiName({ readField }: { readField: ReadFieldFunction }) {
    const clientRef = readField<string>("clientRef");
    const address = readField<boolean>("address");

    return clientRef || address;
}

export default client;
